<template>
    <div class="register">
        <div style="border-bottom: 1px solid rgba(229, 229, 229, 1);">
            <div class="centerWrapper">
                <div class="top">
                    <div class="logo" @click="$router.push('/')">
                        <!-- <img src="../assets/imgs/brand.png"> -->
                        <img v-if="websiteLogo" :src="websiteLogo">
                        <div class="loginBox"></div>
                    </div>
                    <div class="right">
                        <span @click="$router.replace('/login')">登录</span>
                        <span>注册</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="main">
            <div class="centerWrapper">
                <!-- <el-form :model="form" :rules="rules" ref="sliderForm">
                    <el-form-item prop="sliderValue">
                    <div class="slider-container">
                        <el-slider
                        v-model="form.sliderValue"
                        :min="1"
                        :max="10"
                        show-input>
                        </el-slider>
                    </div>
                    <el-button type="primary" @click="submitForm">提交</el-button>
                    </el-form-item>
                </el-form> -->
                <el-form ref="form" label-position="left" :model="form" label-width="100px">
                    <el-form-item label="手机号">
                        <div class="cus">
                            <el-input v-model="form.telephone" autocomplete="off"></el-input>
                            <div class="explain">请填写您常用的手机号码</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="验证">
                        <div class="cus">
                            <sliderValid @slideOver="slideOver"></sliderValid>
                            <div class="explain">拖动滑块到最右侧</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="短信验证">
                        <div class="cus">
                            <el-input v-model="form.smsCode" autocomplete="off">
                                <el-button slot="append" class="sendBtn" :style="slideValidResult?styleObj:{}" @click="sendCode">
                                    {{countDownNum>0?countDownNum+'s后重发':'发送验证码'}}
                                </el-button>
                            </el-input>
                            <div class="explain">填写你获取到的验证码</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="设置密码">
                        <div class="cus">
                            <el-input v-model="form.password" show-password autocomplete="off"></el-input>
                            <div class="explain">密码长度必须大于6位</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="">
                        <div class="cus">
                            <el-button type="info" :style="slideValidResult?registerBtnOgj:{}" @click="clickRegister">注册</el-button>
                            <div class="explain"></div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="footer">
            <div>Copyright @2019-2024{{websiteName}}版权所有，并保留所有权利</div>
            <!-- <div>蜀ICP备2023023644号-2</div> -->
            <div @click="toFiling" style="cursor:pointer;width:fit-content;margin:auto">蜀ICP备2024081010号</div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import sliderValid from "@/components/sliderValid2"
import {sendValidCode,registerUser} from "@/axios/api"
export default {
    components:{
        sliderValid
    },
    data(){
        return{
            userAcc:"",
            password:"",
            form:{
                telephone:"",
                smsCode:"",
                password:"",
            },
            // form: {
            //     sliderValue: 5,
            // },
            // rules: {
            //     sliderValue: [
            //         { required: true, message: '请滑动滑块进行验证', trigger: 'blur' },
            //         { type: 'number', message: '滑块值必须是数字值', trigger: 'blur' },
            //         { min: 1, max: 10, message: '滑块值必须在 1 到 10 之间', trigger: 'blur' }
            //     ]
            // },
            slideValidResult:false,//滑动验证
            styleObj:{
                background:"#00b894",
                color:"#fff",
                height:"51px"
            },
            registerBtnOgj:{
                background:"#00b894",
                color:"#fff",
                borderColor:"#fff"
            },
            countDownNum:0,
            websiteLogo:"",
            websiteName:""
        }
    },
    methods: {
        // login(){
        //     axios({
        //         url:_root+"/v1/user/login",
        //         method:"post",
        //         data:{
        //             userName:"18888888888",
        //             password:"4QrcOUm6Wau+VuBX8g+IPg=="
        //         },
        //         headers:{
        //             "Content-Type":"application/x-www-form-urlencoded"
        //         }
        //     }).then(res=>{
        //         console.log("登录",res)
        //     })
        // }

        clickLogin(){
            if(!this.userAcc){
                this.$message("请输入账号");
                return
            }
            if(!this.password){
                this.$message("请输入密码");
                return
            }
            login({
                userName:this.userAcc,
                password:this.password,
            }).then(res=>{
                console.log("登录结果",res);
                if(res.success==true){
                    localStorage.HPUserInfo=JSON.stringify(res.data);
                    this.$router.push("/housingSource")
                }else{
                    this.$message(res.errorMessage)
                }
            })
        },
        // submitForm() {
        //     this.$refs.sliderForm.validateField('sliderValue', (valid) => {
        //         if (!valid) {
        //             alert('验证失败!');
        //         } else {
        //             alert('验证成功!');
        //             // 执行后续操作
        //         }
        //     });
        // },
        slideOver(val){
            console.log("到头")
            this.slideValidResult=true
        },
        // 发送验证码
        sendCode(){
            if(!this.slideValidResult){
                return
            }
            if(this.countDownNum>0){
                this.$message(this.countDownNum+"s后可重发")
                return
            }
            var t=new Date().getTime()
            sendValidCode({
                telephone:this.form.telephone,
                // sign:MD5.createMD5String(this.form.telephone+'YDJJ#@!'+t)
            }).then(res=>{
                console.log("发送验证码",res);
                // if(res.code=200){
                    this.setCountDown()
                // }
            })
        },
        // 注册
        clickRegister(){
            if(!this.slideValidResult){
                return
            }
            registerUser({
                telephone:this.form.telephone,
                password:this.form.password,
                smsCode:this.form.smsCode
            }).then(res=>{
                console.log("注册结果",res);
                if(res.code==200){
                    this.$message.success(res.msg||res.message)
                    setTimeout(()=>{
                        this.$router.replace('/login')
                    },2000)
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        },
        setCountDown(){
            this.countDownNum=60
            var timer=setInterval(()=>{
                this.countDownNum--;
                if(this.countDownNum<=0){
                    clearInterval(timer);
                }
            },1000)
        },

        toFiling(){
            location.href="https://beian.miit.gov.cn"
        },
        pxToRem(v){
            return Math.round(v/16*100000)/100000
        },
    },
    created() {
        this.styleObj.height=this.pxToRem(51)+'rem'
        if(sessionStorage.websiteLogo){
            this.websiteLogo=sessionStorage.websiteLogo;
            this.websiteName=sessionStorage.websiteName;
        }
        this.$bus.$on('gettingConfig',()=>{
            // console.log(2222,sessionStorage.websiteLogo);
            this.websiteLogo=sessionStorage.websiteLogo;
            this.websiteName=sessionStorage.websiteName;
        })
    },
}
</script>

<style lang="less" scoped>
.register{
    height: 100%;
    // background: url("../assets/imgs/loginBack.webp");
    background-size: 100% 100%;
    background-color: #fff;
    position: relative;
    .top{
        height: 122px;
        // padding: 25px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo{
            width: 200px;
            cursor: pointer;
            img{
                width: 100%;
                height: 85px;
                object-fit: contain;
            }
        }
        .right{
            display: flex;
            align-items: center;
            &>span{
                margin-left: 27px;
                font-size: 24px;
                font-size: 20px;
                font-weight: 400;
                color: #333;
                cursor: pointer;
            }
        }
    }
    .main{
        // .slider-container {
        //     display: flex;
        //     align-items: center;
        //     margin-bottom: 20px;
        // }
        margin-top: 100px;
        .el-form{
            width: 700px;
            margin: auto;
            .el-form-item{
                margin-bottom: 69px;
                .cus{
                    display: flex;
                    .el-input{
                        width: 400px;
                        /deep/.el-input__inner{
                            height: 51px;
                            line-height: 51px;
                        }
                    }
                    &>.el-button{
                        width: 400px;
                        height: 51px;
                        border-radius: 0;
                    }
                    .el-button.sendBtn{
                        width: 113px;
                    }
                    .explain{
                        width: 200px;
                        margin-left: 30px;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(128, 128, 128, 1);
                    }
                }
            }
        }
    }
    .footer{
        height: 40px;
        padding: 40px 0;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: rgba(128, 128, 128, 1);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: content-box;
        position: absolute;
        width: 100%;
        // bottom: 60px;
        bottom: 0;
    }
    
}
</style>